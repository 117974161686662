<template>
  <div class="d-flex flex-column">
    <div id="menu-background"></div>
    <!-- Loading -->
    <LogoLoading v-if="loading"/>
    <!-- Content -->
    <div v-else class="photo-container container d-flex flex-wrap mt-2 p-0">
      <GalleryThumb @showImage="showImage(index)" class="slider-thumb box" v-for="(content, index) in photos" :key="index" :galleryContent="content"/>      
      <NoResultsPlaceholder v-if="!photos.length"></NoResultsPlaceholder>
    </div>
    <FullScreenFile v-if="showImageIndex != null" :startIndex="showImageIndex" @closeRequest="showImageIndex = null" :gallery="photos"></FullScreenFile>
  </div>
</template>

<script>
import GalleryThumb from "@/components/GalleryThumb.vue";
import LogoLoading from "@/components/LogoLoading.vue"
import { mapActions, mapState } from "vuex";
import InputGetList from "@/classes/InputGetList";
import FullScreenFile from "@/components/FullScreenFile.vue";
import { API_HELPER } from "@/helpers/api.js";

const DEFAULT_ITEMS_PER_PAGE = 8;

export default {
  components:{GalleryThumb, LogoLoading, FullScreenFile},
    data(){
      return{
        type: null,
        title: '',
        showImageIndex: null
      }
    },
    metaInfo () {
      return { 
            title: this.$t("vueRoutes.galleryTitle"),
            titleTemplate: "%s - EPTUS",
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRoutes.galleryDescription")},
            ],
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.gallery", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.gallery", 'pt')])},
            ]
            
        } 
    },
    mounted: function()
    { 
      this.initializePage();
    },
    methods:
    {
      ...mapActions({
          getGallery: 'heros/getList',
      }),
      initializePage: function()
      {
        const itemsPerPage = 99;
        const currentPage = 1;
        const searchText = '';
        const sorter = 2; // desc
        const propertyName = "id";
        
        //Make request
        var input = new InputGetList(itemsPerPage, currentPage, sorter, propertyName, searchText);

        this.getGallery(input); 
      },
      showImage: function(index)
      {
        this.showImageIndex = index;
      },  
    },
    computed:
    {
      ...mapState({ 
          galleryOutput: state => state.heros.listOutput,
      }),
      loading: function(){
          return this.galleryOutput == null;
      },
      photos: function() {
        var result = [];

        if(this.galleryOutput && this.galleryOutput.data && this.galleryOutput.data.heroItems)
        {
          result = this.galleryOutput.data.heroItems.filter(obj => {
            return obj.isGallery
          }).map(x=> x.image);
        }

        return result;
      },
    }
}
</script>

<style scoped>
#menu-background
{
  height: 115px;
  background-color: #575757;
  margin-bottom: 25px;
}

/* Title */
h1{
    font-size: 3rem!important;
}
.title{
    justify-content: center;
    display: flex;
}
.title h2{
    margin:0;
    font-weight: bold !important;
}
hr{
  opacity: 1 !important;
  background-color:transparent;
  border: 1px solid black !important;
  margin: 1rem auto;
}
.see > p {
  margin-top: 1rem;
  cursor: pointer;
}
.skeleton-box
{
  min-height: 245px;
}

/* Size of poi elements: these are not done using bootstrap to have correct alignments, using gap */
.photo-container /* Four columns */
{
  gap: 2%;
}
.photo-container > .slider-thumb,
.photo-container > span
{
  width: 22%;
  height: 245px;
  margin-bottom: 2%;
  flex-grow: 1;
  max-width: 310px;
}

@media (max-width: 1199px) 
{
  .photo-container
  {
    gap: 5%;
    padding: 0 1rem;
  }

  .photo-container > .slider-thumb,
  .photo-container > span
  {
    width: 30%;
    max-width: 30%;
    margin-bottom: 5%;
  }
}
/* Header bar */
@media (max-width: 767px) /* Two columns */
{
  .photo-container
  {
    gap: 10%;
    padding: 0 1rem;
  }

  .photo-container > .slider-thumb,
  .photo-container > span
  {
    width: 45%;
    max-width: 45%;
    margin-bottom: 10%;
  }
}

@media (max-width: 575px) /* Two columns */
{
    h1{
        font-size: 2rem!important;
    }
  .photo-container
  {
    padding: 0 1rem;
    justify-content: center;
  }

  .photo-container > .slider-thumb,
  .photo-container > span
  {
    width: 95%;
    max-width: 95%;
    margin-bottom: 3%;
  }
  .title h2{
   font-size: 20px;
  }
}
</style>