<template>
  <div class="wines">
    <div class="container"> 
      <div class="row justify-content-center">
        <div class="d-flex justify-content-between align-items-center mt-5">
          <h1 class="secondary-color">{{title.toUpperCase()}}</h1>
          <router-link class="btn-border m-2 text-dec-none" exact :to="{ name: 'Wines' }">{{$t("button.conhecaMais").toUpperCase()}}</router-link>
        </div>
        <vueper-slides
          v-if="wines.length"
          style="padding: 0 50px"
          class="no-shadow"
          :class="{'big-slider': wines.length > 3}"
          fixed-height="650px"
          :visible-slides="3"
          :slide-ratio="1/2"
          :dragging-distance="70"
          :touchable="false"
          :bullets="false"
          :breakpoints="{
            910: { visibleSlides: 2, touchable: true},
            767: { visibleSlides: 1, slideRatio: 1/1, touchable: true},
            }"
          >
        <vueper-slide v-for="(wine, index) in wines" :key="index">
          <template v-slot:content>
            <WineThumb :wineContent="wine" @click="wineDetail(index)"/>
          </template>
        </vueper-slide>
        </vueper-slides>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import WineThumb from '@/components/HomePage/wines/WineThumb.vue';
import { mapState, mapActions } from 'vuex';
import InputGetList from "@/classes/InputGetList";
import i18n from '@/helpers/i18n';

export default {
  components: {VueperSlides,VueperSlide,WineThumb},
  props: {
    title: {
      type: String,
      default: i18n.t("wines.title")
    },
    type: String
  },
  mounted() 
  {
    this.initializePage();
  },
  computed: {
    ...mapState({ 
        winesOutput: state => state.wines.listOutput,
    }),
    wines(){
      return this.winesOutput && this.winesOutput.data ? this.winesOutput.data : [];
    },
  },
  methods:{
    ...mapActions('wines', ['getList']),
    wineDetail(index){
        this.$router.push({name: 'WineDetail', params: {wine: this.wines[index], wineSlug: this.wines[index].slug}});
    },

    initializePage(){

        // GET list of wines
        const itemsPerPage = 999;
        const currentPage = 1;
        const sorter = 1; // asc
        const propertyName = "position";
        const searchText = '';
        
        //Make request
        var input = new InputGetList(itemsPerPage, currentPage, sorter, propertyName, searchText);
        input.type = this.type;
        
        this.getList(input); 
    }
  },
}
</script>

<style scoped>
>>>.vueperslide {
  transition: .3s ease-in-out;
  transform: scale(0.8);
}
>>>.big-slider .vueperslide--active,
>>>.big-slider .vueperslide:hover{
    transform: scale(0.9);
}
>>>.vueperslides--fixed-height.vueperslides--bullets-outside {
    margin-bottom: 0;
}
>>>.vueperslides__bullet .default {
  border: 1px solid currentColor;
  background-color: transparent;
  box-shadow: 0 0 1px rgba(0,0,0,.5),0 0 3px rgba(0,0,0,.3);
  transition: .4s ease-in-out;
  box-sizing: border-box;
}
>>>.vueperslides__bullet--active .default{
  background-color: #E4A64D!important;
}
@media screen and (max-width: 767px) {
  >>>.vueperslide {
    transition: .3s ease-in-out;
    transform: unset;
  }
>>>.vueperslide--active{
    transform: unset;
  }
}
/deep/.vueperslides__arrow svg
{
  stroke: black !important; 
}
</style>