<template>
    <div class="hero">
      <div class="hero-content">
            <vueper-slides
                v-if="heros.length"
                :bullets="false" 
                fixed-height="100vh" 
                class="img-slide" 
                :touchable="false"
                autoplay
                >
                <vueper-slide v-for="(hero, i) in heros" :key="i">
                    <template v-slot:content>
                        <HeroThumb :heroContent="hero" />
                    </template>
                </vueper-slide>
            </vueper-slides>
            <a @click="scroll" class="arrow-container">
                <div class="arrow"></div>
                <div class="arrow"></div>
                <div class="arrow"></div>  
            </a>
        </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import HeroThumb from '@/components/HomePage/hero/HeroThumb.vue';
import InputGetList from "@/classes/InputGetList";
import { mapState, mapActions } from 'vuex';

export default {
    components: {VueperSlides,VueperSlide, HeroThumb},
    mounted() 
    {
        this.initializePage()
    },
    computed: {
        ...mapState({ 
            herosOutput: state => state.heros.listOutput,
        }),
        heros: function () {
            var result = [];

            if(this.herosOutput && this.herosOutput.data && this.herosOutput.data.heroItems && this.herosOutput.data.heroItems.length)
            {
                result = this.herosOutput.data.heroItems.filter(obj => {
  return obj.isHome
});
            }

            return result;
        },
    },
    methods:
    {
        ...mapActions('heros', ['getList']),
        scroll(){
            // element which needs to be scrolled to
            var element = document.querySelector("#about");

            // scroll to element
            element.scrollIntoView();
        },
        initializePage()
        {
            // GET list of hero
            const itemsPerPage = 10;
            const currentPage = 1;
            const sorter = 1; // desc
            const propertyName = "id";
            const searchText = '';
            
            //Make request
            var input = new InputGetList(itemsPerPage, currentPage, sorter, propertyName, searchText);
            
            // List specific parameters
            input.visible= true;

            this.getList(input); 
        }
    },

}
</script>

<style scoped>
.hero{
    position: relative;
    width: 100%;
    height: 100vh;
}
.hero-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}
.hero-content::before{
    content: "";
    width: 100%;
    height: 30%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg,#000,rgba(84,84,84,0));
    mix-blend-mode: multiply;
    opacity: .45;
    z-index: 9;
}
.img-slide{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
>>>.vueperslides__arrows button{
    z-index: 10!important;
}
>>>.vueperslide__title h1{
    font-size: 4rem;
    color: white;
    width: 400px;
    font-weight: 700;
}
>>>.vueperslide__content h5{
    font-size: 2rem;
    color: white;
    width: 400px;
    font-weight: 700;
}
 .arrow-container {
	 display: block;
	 width: 100px;
	 height: 100px;
	 position: absolute;
     bottom: 0;
	 left: 50%;
	 transform: translate(-50%, -50%) rotateZ(0deg);
     z-index: 1;
}
 .arrow-container:hover {
	 cursor: pointer;
}
 .arrow-container:hover .arrow {
	 top: 50%;
}
 .arrow-container:hover .arrow:before {
	 transform: translate(-50%, -50%) rotateZ(-30deg);
}
 .arrow-container:hover .arrow:after {
	 transform: translate(-50%, -50%) rotateZ(30deg);
}
 .arrow {
	 position: absolute;
	 left: 50%;
	 transition: all 0.4s ease;
}
 .arrow:before, .arrow:after {
	 transition: all 0.4s ease;
	 content: '';
	 display: block;
	 position: absolute;
	 transform-origin: bottom right;
	 background: #fff;
	 width: 4px;
	 height: 30px;
	 border-radius: 10px;
	 transform: translate(-50%, -50%) rotateZ(-45deg);
}
 .arrow:after {
	 transform-origin: bottom left;
	 transform: translate(-50%, -50%) rotateZ(45deg);
}
 .arrow:nth-child(1) {
	 opacity: 0.3;
	 top: 35%;
}
 .arrow:nth-child(2) {
	 opacity: 0.6;
	 top: 55%;
}
 .arrow:nth-child(3) {
	 opacity: 0.9;
	 top: 75%;
}
</style>