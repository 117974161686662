<template>
  <div class="intro-banner">
    <div class="image"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/ageV.jpg') + ')',
      }"
    >
    </div>
    <div class="container">
      <div class="logo">
        <img loading=lazy :src="logo" alt="EPTUS" />
      </div>
      <div class="content">
        <div class="disclaimer">{{$t("ageWarning")}}</div>
        <button @click="isConfirmation" class="btn-border-white mt-3 mb-3">{{$t("enter")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      logo: require("@/assets/img/logo_full.png"),
    };
  },
  mounted() {
  },
  methods: {
    isConfirmation() {
      let inHalfADay = 0.5;
      Cookies.set("ageVerified", "true", {
        expires: inHalfADay,
        secure: true
      });
      this.$emit('isClick');
    },
    
  },
};
</script>

<style scoped>
.intro-banner {
  height: 100vh;
  color: #fff;
}
.intro-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(360deg, #000, rgba(84, 84, 84, 0.2));
  z-index: 5;
  height: 100vh;
}
.intro-banner .container {
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  text-align: center;
}
.intro-banner .image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.intro-banner .logo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 55%;
  margin-left: auto;
  margin-right: auto;
}
.intro-banner .logo img {
  display: block;
  margin: 0 auto;
  height: 200px;
  width: auto;
}
img {
  max-width: 100%;
  height: auto;
}

.intro-banner .content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
}

.intro-banner h2 {
  font-size: 2.5rem;
  line-height: 1.2;
}
.intro-banner .disclaimer {
  font-size: 13px;
  line-height: 1.4;
  text-align: center;
  margin-top: 13.33333px;
}

@media screen and (max-width: 992px) {
  .intro-banner {
    height: 620px;
  }
  .intro-banner .logo{
    bottom: 60%;
  }
  .intro-banner .content{
      top: 45%;
  }
  .intro-banner h2 {
    font-size: 34px;
    line-height: 1.2;
  }
}
</style>
