<template>
  <section class="about" id="about">
        <div class="container">
            <!-- First content of about - text -->
            <div v-if="primaryInfo" class="row justify-content-center">
                <h5 v-html="primaryInfo.value" class="first-text"></h5>
                <div class="mt-4">
                    <router-link class="btn-border m-2 text-dec-none" :to="{ name: 'StaticInfos', params: {slug: 'projeto'} }">{{$t("button.descubraMais").toUpperCase()}}</router-link>
                    <!-- <router-link class="btn-border m-2 text-dec-none" :to="{ name: 'Wines' }">{{$t("button.osVinhos").toUpperCase()}}</router-link> -->
                </div>
            </div>
        </div>
        <!-- Second content of about - image -->
        <div class="mt-5">
            <div class="img-full-width-parallax" :style="{backgroundImage: 'url('+ require('@/assets/img/about.jpg') +')'}"/>
        </div>
        <!-- Third content of about - text -->
        <div v-if="secondaryinfo" class="container-fluid container-box-bg">
            <h5 v-html="secondaryinfo.value" class="second-text"></h5>
            <div class="mt-4">
                <router-link class="btn-border m-2 text-dec-none" :to="{ name: 'StaticInfos', params: {slug: 'dourovalley'} }">{{$t("button.descubraMais").toUpperCase()}}</router-link>
            </div>
        </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from "@/router";

export default {
    data() {
        return {
            primaryInfo: null,
            secondaryinfo: null
        }
    },

    mounted() 
    {
        this.getDetails("primaryinfo");
        this.getDetails("secondaryinfo");
    },
    computed: {
        ...mapState("about", ["detailOutput"]),
    },
    methods: {
        ...mapActions('about', ['getDetails']),
    },
    watch:{
        detailOutput: function(val)
        {
          if(val != null)
          {
            if(val && val.data && val.data.key === "primaryinfo")
            {
                this.primaryInfo = val.data;
            }
            else if(val && val.data && val.data.key === "secondaryinfo"){
                this.secondaryinfo = val.data;
            }
            else
            {
              router.push("/");
            }
          }
        }
    }

}
</script>

<style scoped>
h1{
    font-weight: bold;

}
h5{
    font-size: 20px;
    line-height: 32px;
}
.img-full-width-parallax{
    height: 73vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    background-attachment: fixed;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}
.container-box-bg {
    margin-top: -80px;
    text-align: center;
    position: relative;
    background-color: #212121;
    padding-top: 64px;
    padding-bottom: 64px;
    z-index: 2;
}
>>>.second-text h1,
>>>.first-text h1{
    color: #E4A64D;
    font-weight: bold;
}
@media screen and (min-width: 1500px) {
    >>>.second-text{
        padding: 0 19rem;
    }
}
@media screen and (max-width: 991px) {
    .img-full-width-parallax{
        height: 600px;
        background-attachment: scroll;
    }
}
</style>