import { render, staticRenderFns } from "./WinesPage.vue?vue&type=template&id=97b01a7e&scoped=true&"
import script from "./WinesPage.vue?vue&type=script&lang=js&"
export * from "./WinesPage.vue?vue&type=script&lang=js&"
import style0 from "./WinesPage.vue?vue&type=style&index=0&id=97b01a7e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97b01a7e",
  null
  
)

export default component.exports