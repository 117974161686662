<template>
    <section id="app-header">
        <b-navbar :class="isWineDetail" class="w-100" toggleable="lg" type="dark">
            <div class="container-fluid">
                <b-navbar-brand class="d-block d-lg-none">
                    <div class="nav-link pt-0">
                        <img loading=lazy :src=" this.$route.name == 'WineDetail' || this.$route.name == 'TeamDetail'  ? require('../assets/img/logo.png') : require('../assets/img/logo_white.png')" alt="Logo" title="Logo" />
                    </div>
                </b-navbar-brand>
                <b-collapse class="nav-menu justify-content-between" id="nav-collapse" is-nav>
                    <b-navbar-brand>
                        <div class="nav-link pt-0">
                            <img loading=lazy :src=" this.$route.name == 'WineDetail' || this.$route.name == 'TeamDetail' ? require('../assets/img/logo.png') : require('../assets/img/logo_white.png')" alt="Logo" title="Logo" />
                        </div>
                    </b-navbar-brand>
                    <b-navbar-nav class="ml-auto fw-bold" :class="opacityActive">
                        <b-nav-form>
                            <router-link class="nav-link" :to="{ name: 'HomePage'}" active-class="active" exact>{{$t("header.home").toUpperCase()}}</router-link>
                        </b-nav-form>
                        <b-nav-form>
                            <router-link class="nav-link" :to="{ name: 'StaticInfos', params: {slug: 'projeto'} }" active-class="active" exact>{{$t("header.projeto").toUpperCase()}}</router-link>
                        </b-nav-form>
                        <b-nav-form>
                            <router-link class="nav-link" :to="{ name: 'StaticInfos', params: {slug: 'douro-valley'} }" active-class="active" exact>{{$t("header.dourovalley").toUpperCase()}}</router-link>
                        </b-nav-form>
                        <b-nav-form>
                            <router-link class="nav-link" :to="{ name: 'Wines' }" active-class="active">{{$t("header.vinhos").toUpperCase()}}</router-link>
                        </b-nav-form>
                        <b-nav-form>
                            <router-link class="nav-link" :to="{ name: 'StaticInfos', params: {slug: 'azeite-virgem-extra'} }" active-class="active" exact>{{$t("header.azeite").toUpperCase()}}</router-link>
                        </b-nav-form>
                        <b-nav-form>
                            <router-link class="nav-link" :to="{ name: 'Gallery' }" active-class="active">{{$t("header.galeria").toUpperCase()}}</router-link>
                        </b-nav-form>
                        <b-nav-form>
                            <router-link class="nav-link" :to="{ name: 'Contact' }" active-class="active">{{$t("header.contacto").toUpperCase()}}</router-link>
                        </b-nav-form>

                    </b-navbar-nav>
                    <b-navbar-nav class="ml-auto fw-bold" :class="{'lang-opacity': this.$route.name}">
                        <b-nav-form>
                            <a class="nav-link" href="#" @click.prevent="setLocale('pt')">{{$t("header.pt")}}</a>                 
                            <a class="nav-link" href="#" @click.prevent="setLocale('en')">{{$t("header.en")}}</a>
                        </b-nav-form>
                    </b-navbar-nav>
                </b-collapse>
                <b-button class="d-lg-none" id="navbar-toggle" v-b-toggle.sidebar-right variant="transparent" size="lg">
                    <b-icon icon="list" :variant="this.$route.name == 'WineDetail' || this.$route.name == 'TeamDetail' ? 'black' : 'white'"></b-icon>
                </b-button>
                <b-sidebar class="w-100" id="sidebar-right" title="Sidebar" right no-header bg-variant="no-light">
                    <template #default="{ hide }">
                        <SideMenu @close="hide"></SideMenu>
                    </template>
                </b-sidebar>
            </div>
        </b-navbar>
    </section>
</template>

<script>
import SideMenu from '@/components/menus/SideMenu.vue';


export default {
    components: {
        SideMenu
    },
    data() {
        return {
        }
    },
    computed:{
        isWineDetail() {
            // Different navbar on these pages because they don't have an image in the header.
            return {
                'navbar-wine-detail': this.$route.name == 'WineDetail' || this.$route.name == 'TeamDetail',
            }
        }, 
        // Opacity = 1 for for TeamDetails page because these don't exist in the navigation bar
        opacityActive() {
            return {
                'opacity-active': this.$route.name == 'TeamDetail',
            }
        }, 
    },
    methods: {
        setLocale(locale) {
            this.$i18n.locale = locale;
            localStorage.setItem('locale', locale);
            this.$router.push("/");
            this.$router.go(0);
        },
    },
};
</script>

<style scoped>
#app-header{
    height: 112px;
    background-color: transparent;
    position: absolute;
    width: 100%;
    display: flex;
    z-index: 11;
}
.form-inline .form-inline a,
>>>.btn-group > .btn{
    font-size: 0.8rem;
    padding-left: 1rem;
    padding-right: 1rem;
    letter-spacing: .1rem;
    color: #fff;
    transition: all .4s ease-in-out;
    cursor: pointer;
}
.navbar-brand{
    width: auto;
    height: 70px;
}
.navbar-brand img{
    width: auto;
    height: 64px;
}
.form-inline{
    display: flex;
}
>>>.b-sidebar{
    width: 100%;
}
.opacity-active a:not(.active),
.lang-opacity a:not(.active){
    opacity: 1!important;
}
.navbar-wine-detail{
    background-color:#212121;
}
.navbar-wine-detail .form-inline a{
    color: white;
}
.navbar-wine-detail .form-inline a:hover{
    color: gray;
}
.navbar-wine-detail .form-inline .nav-link.active{
    color: gray !important;
}
.form-inline a:not(.active){
    opacity: .6;
}
.form-inline a:active{
    opacity: 1;
}
.btn-check:focus + .btn, .btn:focus {
    box-shadow: none !important;
}
>>>.btn-secondary{
    background-color: unset!important;
    border-color: transparent!important;
} 
#navbar-toggle{
    font-size: 2.25rem;
}

>>>.dropdown-item:active {
color: #fff;
background: rgba(108, 74, 58, 1);
border-color: rgba(108, 74, 58, 1);
} 
</style>
