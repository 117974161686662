<template>
  <div class="home-page">
    <Hero class="mb-5"/>
    <About class="mt-5 mb-5"/>
    <Wines class="mt-5 mb-5"/>
    <Team class="mt-5 mb-5"/>
    <Prizes v-if="prizes.length" class="mt-5"/>
    <div v-if="loading" id="fullscreen-loading">
      <LogoLoading/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/HomePage/hero/Hero.vue";
import About from "@/components/HomePage/about/About.vue";
import Wines from "@/components/HomePage/wines/Wines.vue";
import Prizes from "@/components/HomePage/prizes/Prizes.vue";
import Team from "@/components/HomePage/team/Team.vue";
import { UTILS_HELPER } from "@/helpers/utils.js";
import { mapState } from 'vuex';
import LogoLoading from "@/components/LogoLoading.vue"
import router from '@/router';

export default {
  metaInfo () {
    return { 
      title: 'EPTUS',
      htmlAttrs: {
          lang: this.$i18n.locale,
      },
      meta: 
      [
        {name: 'description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
        {name: 'keywords', content: this.$t("vueRoutes.defaultKeywords")},
        // Twitter Card
        {name: 'twitter:card', content: "summary"},
        {name: 'twitter:title', content: 'EPTUS'},
        {name: 'twitter:description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
        // {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_FRONT, "assets/img/hero/SLIDE_07.jpg"]) },
        //Facebook OpenGraph
        {property: 'og:title', content: 'EPTUS'},
        {property: 'og:site_name', content: 'EPTUS'},
        {property: 'og:type', content: 'website'},
        // {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_FRONT, "assets/img/hero/SLIDE_07.jpg"]) },
        {property: 'og:description', content: UTILS_HELPER.htmlToText(this.$t("intro"))}
      ], 
      link: [
        {rel: 'alternate', hreflang: 'pt', href: process.env.VUE_APP_URL_ROOT},
        {rel: 'alternate', hreflang: 'en', href: process.env.VUE_APP_URL_ROOT},
      ]
    } 
  },
  name: "HomePage",
  components: {
    Hero,
    About,
    Wines,
    Prizes,
    Team,
    LogoLoading
  },
  computed: {
    ...mapState({ 
      herosOutput: state => state.heros.listOutput,
      prizesOutput: state => state.prizes.listOutput,
      winesOutput: state => state.wines.listOutput,
      membersOutput: state => state.members.listOutput,
    }),
    ...mapState("about", ["detailOutput"]),
    loading: function()
    {
      return this.herosOutput === null || this.detailOutput == null;
    },
    prizes(){
      return this.prizesOutput && this.prizesOutput.data ? this.prizesOutput.data : [];
    }
  },
  methods:
  {
    teamClicked: function()
    {
      router.push({ name: "TeamDetail" });
    }
  }
};
</script>

<style scoped>
#fullscreen-loading
{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  z-index: 999;
}
</style>