<template>
  <div>
    <div class="top-img">
      <img loading=lazy src="../assets/img/topWine.jpg" class="top-bg" alt="Wines Image" />
    </div>
    <div class="categories">
      <div class="container">
        <div class="col-12">
          <div v-if="showCategories" id="swiper-categories" class="swiper-container">
            <div class="swiper-wrapper">
              <div class="tab" :class="{ active: activeType == type.value }" v-for="type in types" :key="type.value">
                <h4 class="category" @click="initializePage(activeType = type.value)">
                  {{ type.category }}
                </h4>
              </div>
            </div>
          </div>
          <LogoLoading v-if="!wines"/>
          <div v-else class="wines-list">
            <div class="container">
                <div class="row">
                    <template v-if="wines.length">
                      <div class="col-md-6 col-12" v-for="(wine,index) in wines" :key="wine.id">
                        <a @click="wineDetail(index)" class="clean-link">
                            <WineThumb class="card-wine" :wineContent="wine"/>
                            <!-- <div class="card-wine cursor-pointer">
                                <img loading=lazy :src="joinPaths(wine.photoPath)" alt=""/>
                                <div class="name">{{wine.name}}</div>
                                <div class="year" v-if="showYear">{{wine.year}}</div>
                            </div> -->
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <NoResultsPlaceholder/>
                    </template>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import InputGetList from "@/classes/InputGetList";
import { API_HELPER } from "@/helpers/api.js";
import LogoLoading from "@/components/LogoLoading.vue"
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue"
import WineThumb from '@/components/HomePage/wines/WineThumb.vue';

export default {
  props: {
    showCategories:{
      type: Boolean
    },
    showYear: {
        type: Boolean
    }
  },
  metaInfo () {
    return { 
          title: this.$t("vueRoutes.winesTitle"), 
          titleTemplate: '%s - EPTUS', 
          htmlAttrs: {
            lang: this.$i18n.locale,
          },
          meta: 
          [
            {name: 'description', content: this.$t("vueRoutes.winesDescription")},
            {name: 'keywords', content: this.wines && this.wines.length ? this.wines.map(x=> x.name).join(", ") : this.$t("vueRoutes.winesKeywords")},
            // Twitter Card
            {name: 'twitter:card', content: "summary"},
            {name: 'twitter:title', content: this.$t("vueRoutes.winesTitle")},
            {name: 'twitter:description', content: this.$t("vueRoutes.winesDescription")},
            // {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/img/hero/SLIDE_07.jpg"]) },
            //Facebook OpenGraph
            {property: 'og:title', content: this.$t("vueRoutes.winesTitle")},
            {property: 'og:site_name', content: 'EPTUS'},
            {property: 'og:type', content: 'website'},
            // {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/img/hero/SLIDE_07.jpg"]) },
            {property: 'og:description', content: this.$t("vueRoutes.winesDescription")},
          ], 
          link: [
            {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.wines", 'pt')])},
            {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.wines", 'en')])},
          ]
      } 
  },
  components: {
    LogoLoading,
    NoResultsPlaceholder,
    WineThumb
  },
  data() {
    return {
      types: [{ category: this.$t("type.all"), value: null}, { category: this.$t("type.white"), value: 1},{ category: this.$t("type.red"), value:2 },{ category: this.$t("type.reserve"), value: 3}],
      activeType: null,
    };
  },
  mounted() 
  {
      this.initializePage(this.activeType);
  },
  computed: {
    ...mapState({ 
        winesOutput: state => state.wines.listOutput,
    }),
    wines(){
      return this.winesOutput && this.winesOutput.data ? this.winesOutput.data : null;
    },
  },
  methods:{
    ...mapActions('wines', ['getList']),

    wineDetail(index){
        this.$router.push({name: 'WineDetail', params: {wine: this.wines[index], wineSlug: this.wines[index].slug}});
    },
    joinPaths(relativePath){
      return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
    },
    initializePage(activeType){

        // GET list of wines
        const itemsPerPage = 999;
        const currentPage = 1;
        const sorter = 1; // asc
        const propertyName = "position";
        const searchText = '';
        
        //Make request
        var input = new InputGetList(itemsPerPage, currentPage, sorter, propertyName, searchText);
        
        // List specific parameters
        input.type = activeType

        this.getList(input); 
    }
  }
};
</script>

<style scoped>
.top-img {
  position: relative;
  width: 100%;
  height: 465px;
}
.top-img .top-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.top-img::before {
  content: "";
  width: 100%;
  height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #000, rgba(84, 84, 84, 0));
  mix-blend-mode: multiply;
  opacity: 0.45;
  z-index: 4;
}
.categories {
  padding-top: 40px;
  padding-bottom: 80px;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.categories .swiper-wrapper {
  display: flex;
  justify-content: center;
}

.tab {
  cursor: pointer;
  opacity: 0.3;
}
.active {
  color: #E4A64D;
  opacity: 1;
}
.categories .tab {
  width: auto;
}
.tab {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}
.categories .category,
.categories .year{
  display: inline-block;
  font-size: 16px;
  line-height: 23px;
  padding: 11px 32px;
  text-transform: uppercase;
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
  font-weight: bold;
}
.categories .category:hover {
    opacity: 1;
    color: #E4A64D;
}
.card-wine {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}
.card-wine:hover
{
  transform: scale(1.1);
}
/deep/.card-wine img {
    max-height: 480px;
    height: 480px;
    width: auto;
    margin-bottom: 20px;
    transition: all .4s ease-in-out;
}
.clean-link {
    color: inherit;
    text-decoration: none;
}
/deep/.card-wine .name {
    font-size: 22px;
    line-height: 28px;
    max-width: 270px;
    color: #E4A64D;
    margin-bottom: 10px;
    text-align: center;
}
/deep/.card-wine .category {
    color: #575757;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    max-width: 270px;
}
@media screen and (max-width: 991px) {
  .categories .swiper-wrapper{
    display: block;
  }
}

/deep/.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
  height: unset !important;
}
</style>
