<template>
  <section>
    <LogoLoading v-if="!detailPage"/>
    <div v-else>
      <div v-if="detailPage">
        <div class="header-top" :class="{'small-top': !getImage}">
          <img v-if="getImage" loading=lazy :src="getImage" class="top-bg" alt="Image">
        </div>
        <div v-for="tab in tabs" :key="tab.id">
          <div class="about-text">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10 col-12">
                  <div class="section-title">
                    {{tab.title}}
                  </div>
                  <div class="p" v-html="tab.content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import { API_HELPER } from "@/helpers/api.js";
import LogoLoading from "@/components/LogoLoading.vue"
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
  metaInfo () {
    var result = 
    { 
      title: this.detailPage ? `${this.detailPage.title} - EPTUS` : "EPTUS", 
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: 
      [
        {name: 'description', content: this.detailPage && this.detailPage.tabs && this.detailPage.tabs.length ? UTILS_HELPER.htmlToText(this.detailPage.tabs[0].content) : this.$t("intro")},
        {name: 'keywords', content: this.$t("vueRoutes.defaultKeywords")},
        // Twitter Card
        {name: 'twitter:card', content: "summary"},
        {name: 'twitter:title', content: this.detailPage ? `${this.detailPage.title} - EPTUS` : "EPTUS"},
        {name: 'twitter:description', content: this.detailPage && this.detailPage.tabs && this.detailPage.tabs.length ? UTILS_HELPER.htmlToText(this.detailPage.tabs[0].content) : this.$t("intro")},
        // {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/img/hero/SLIDE_07.jpg"]) },
        //Facebook OpenGraph
        {property: 'og:title', content: this.detailPage ? `${this.detailPage.title} - EPTUS` : "EPTUS"},
        {property: 'og:site_name', content: 'EPTUS'},
        {property: 'og:type', content: 'website'},
        // {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/img/hero/SLIDE_07.jpg"]) },
        {property: 'og:description', content: this.detailPage && this.detailPage.tabs && this.detailPage.tabs.length ? UTILS_HELPER.htmlToText(this.detailPage.tabs[0].content) : this.$t("intro")},
      ], 
    };

    if(this.$route.params.slug)
    {
      result.link = 
      [
        {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$route.params.slug])}`},
        {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$route.params.slug])}`},
      ];
    }

    return result; 
  }, 
  components: {
    LogoLoading
  },
data() {
    return {
    };
  },
mounted() 
  {
    this.initializePage();
  },
computed: {
    ...mapState("staticInfos", ["detailOutput"]),
    detailPage: function () {
      return this.detailOutput && this.detailOutput.data ? this.detailOutput.data : null;
    },
    getImage: function() {
      return this.detailPage && this.detailPage.image ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.detailPage.image]) : "";      

    },
    tabs: function () {
      return this.detailPage && this.detailPage.tabs && this.detailPage.tabs.length ? this.detailPage.tabs : [];
    },
  },
  methods:
  {
    ...mapActions('staticInfos', ['getDetails']),

    initializePage()
    {
      if (!this.$route.params.slug) {
        router.push({ name: "HomePage" });
      }else{
        this.getDetails(this.$route.params.slug);
       }
    }
  },
  watch: {
    $route(to) {
      if(to.name)
      {
        this.initializePage();
      }
    },
  }
};
</script>

<style scoped>
.header-top {
  height: 700px;
  position: relative;
  width: 100%;
}
.header-top::before {
  content: "";
  width: 100%;
  height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg,#000,rgba(84,84,84,0));
  mix-blend-mode: multiply;
  opacity: .45;
  z-index: 4;
}
.header-top .top-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.small-top
{
  height: 150px;
}
.small-top::before
{
  opacity: 1;
  background: #E4A64D;
}
.about-text {
  position: relative;
  padding-top: 64px;
  padding-bottom: 88px;
  text-align: center;
}
.section-title {
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 24px;
  text-transform: uppercase;
  color: #E4A64D;
}
.p {
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 32px;
  color: #575757;
}

>>>a
{
  color: #E4A64D;
}

>>>a:hover
{
  color: #575757;
}
</style>
